import React, { useState, useEffect } from "react";
import "./Clock.css";
import AnimatedDigit from "./AnimatedDigit";

function Clock() {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);

    return function cleanup() {
      clearInterval(timerID);
    };
  });

  function tick() {
    setTime(new Date());
  }

  const hours = time.getHours().toString().padStart(2, "0");
  const minutes = time.getMinutes().toString().padStart(2, "0");
  const seconds = time.getSeconds().toString().padStart(2, "0");

  return (
    <div className="clock">
      <AnimatedDigit digit={hours[0]} />
      <AnimatedDigit digit={hours[1]} />
      <span className="separator">:</span>
      <AnimatedDigit digit={minutes[0]} />
      <AnimatedDigit digit={minutes[1]} />
      <span className="separator">:</span>
      <AnimatedDigit digit={seconds[0]} />
      <AnimatedDigit digit={seconds[1]} />
    </div>
  );
}

export default Clock;
