import React from "react";
import { CSSTransition } from "react-transition-group";
import Clock from "./Clock";

function App() {
  return (
    <div className="App">
      <CSSTransition in={true} appear={true} timeout={1000} classNames="fade">
        <Clock />
      </CSSTransition>
    </div>
  );
}

export default App;
