import React from "react";
import { motion, AnimatePresence } from "framer-motion";

const AnimatedDigit = ({ digit }) => {
  return (
    <div className="digit-wrapper">
      <AnimatePresence>
        <motion.span
          key={digit}
          initial={{ y: -30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 30, opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="digit"
          style={{ position: "absolute" }}
        >
          {digit}
        </motion.span>
      </AnimatePresence>
    </div>
  );
};

export default AnimatedDigit;
